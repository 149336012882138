import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { awesomeUsersList } from 'src/app/shared/configs/awesomeUsersList';
import { IUserData } from 'src/app/shared/interfaces/IUserData';
import { UserState } from 'src/app/shared/state-management/user/user.state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DevGuard {
  private loggedUser: Observable<IUserData> = inject(Store).select(UserState.loggedUser);
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.loggedUser.pipe(
      map(user => {
        if (!environment.production || awesomeUsersList.includes(user?.gid.toLowerCase())) {
          return true;
        } else {
          this.router.navigate(['/'], { queryParamsHandling: 'merge', queryParams: route.queryParams });
          return false;
        }
      }),
    );
  }
}
